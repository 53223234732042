import Enum from './enum'

/**
 * 調整タイプ
 */
class AdjustmentType extends Enum {
    static none = 0; //変更なし
    static addition = 1; //加算
    static fixed = 2; //固定

    static values() {
        return {
            [this.none]: '',
            [this.addition]: '加算',
            [this.fixed]: '固定',
        }
    }
}

export default AdjustmentType;
