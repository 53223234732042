<template>
    <div disabled class="form-select loading-input" v-if="type === 'select'"></div>
    <div :type="type" disabled class="form-control loading-input" v-else></div>
</template>

<script>
export default {
    name: 'InputLoader',
    props: {
        type: {
            type: String,
            default: 'text'
        },
    }
}
</script>

