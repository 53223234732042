/**
 * 労務費集計
 */
import { isBlank } from '@/utilities/typing';
import User from './user';

class LaborCostStat {
    user;
    work_time;
    labor_cost;
    unit_cost;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.user = new User(properties.user);
        this.work_time = properties.work_time;
        this.labor_cost = properties.labor_cost;
        this.unit_cost = properties.unit_cost;
    }
}

export default LaborCostStat;
