<template>
    <header class="page-title">
        <h1><i class="bi bi-calculator-fill"></i> 収支詳細/入力</h1>
        <button class="btn btn-outline-secondary" @click="$router.go(-1)"><i class="bi bi-reply"></i> 戻る</button>
    </header>

    <section class="section">
        <div class="row mb-3 align-items-end">
            <div class="col-8">
                <label class="form-label">案件名</label>
                <div v-if="loading" class="form-readonly"><text-loader :width="12"></text-loader></div>
                <template v-else>
                    <div class="d-flex align-items-center gap-3">
                        <div class="form-readonly">{{ profit.project.project_name }}</div>
                        <router-link v-if="$store.getters['auth/canManageMaster']()" :to="{name: 'MasterProjectEdit', params: {project: profit.project.project_id}}" class="btn btn-sm btn-outline-primary">編集</router-link>
                    </div>
                </template>
            </div>
            <div class="col-4">
                <label class="form-label">売上タイプ</label>
                <div v-if="loading" class="form-readonly"><text-loader :width="6"></text-loader></div>
                <div v-else class="form-readonly">{{ profit.project.project_type_label }}</div>
            </div>
            <div class="col-4">
                <label class="form-label">年月</label>
                <div v-if="loading" class="form-readonly"><text-loader :width="4"></text-loader></div>
                <div v-else class="form-readonly">{{ profit.month_display }}</div>
            </div>
            <div class="col-4 offset-4 text-end" v-if="editable">
                <div class="btn-group" v-if="loading">
                    <button class="btn btn-outline-secondary">未確定</button>
                    <button class="btn btn-outline-primary">確定</button>
                </div>
                <div class="btn-group" v-else-if="profit.is_fixed">
                    <button class="btn btn-outline-secondary" @click="unfix">未確定</button>
                    <button class="btn btn-primary">確定</button>
                </div>
                <div class="btn-group" v-else>
                    <button class="btn btn-secondary">未確定</button>
                    <button class="btn btn-outline-primary" @click="fix">確定</button>
                </div>
            </div>
            <div class="col-4 offset-4 text-end" v-else>
                <div v-if="loading" class="badge bg-secondary">...</div>
                <div v-else-if="profit.is_fixed" class="badge bg-primary">確定</div>
                <div v-else class="badge bg-secondary">未確定</div>
            </div>
        </div>

        <table class="table table-layout-fixed">
            <tbody>
                <tr class="table-light text-center">
                    <th>売上</th>
                    <th>労務費</th>
                    <th>外注費</th>
                    <th>諸経費</th>
                    <th>粗利</th>
                    <th>粗利率</th>
                </tr>
                <tr v-if="!loading">
                    <td class="text-end text-primary fw-bold">{{ $helper.number(profit.sales_price) }} 円</td>
                    <td class="text-end">{{ $helper.number(profit.labor_cost) }} 円</td>
                    <td class="text-end">{{ $helper.number(profit.subcontract_cost) }} 円</td>
                    <td class="text-end">{{ $helper.number(profit.incidental_cost) }} 円</td>
                    <td class="text-end text-primary fw-bold">{{ $helper.number(profit.gross_profit) }} 円</td>
                    <td class="text-end text-primary fw-bold">{{ gross_profit_rate }}</td>
                </tr>
                <tr v-else>
                    <td class="text-end text-primary fw-bold"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end text-primary fw-bold"><text-loader :width="6"></text-loader></td>
                    <td class="text-end text-primary fw-bold"><text-loader :width="6"></text-loader></td>
                </tr>
            </tbody>
        </table>
    </section>

    <section class="section" v-if="loading">
        <header class="section-title">
            <h2>売上</h2>
        </header>
        <div class="p-5">
            <inline-loader></inline-loader>
        </div>
    </section>

    <section class="section" v-if="profit.project.project_type === ProjectType.ONE_OFF">
        <header class="section-title">
            <h2>売上 <small>(単発)</small></h2>
        </header>
        <table class="table">
            <thead>
                <tr>
                    <th class="col-4">フェーズ</th>
                    <th class="col-4">見積額</th>
                    <th class="col-4">前月進捗率</th>
                    <th class="col-4">今月進捗率</th>
                    <th class="col-4">今月進捗</th>
                    <th class="col-4">今月売上</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="progress of profit.progresses" :key="progress.progress_id">
                    <td>{{ progress.phase.phase_name }}</td>
                    <td class="text-end">{{ $helper.number(progress.phase.sales_price) }}</td>
                    <td class="text-end">{{ progress.progress_start }}％</td>
                    <td class="text-end">
                        <div v-if="editable" class="input-group">
                            <form-input-number v-model="progress.progress_rate" :max="100 - progress.progress_start"></form-input-number>
                            <span class="input-group-text">％</span>
                        </div>
                        <span v-else class="fw-bold">{{ $helper.number(progress.phase.progress_rate ?? 0) }}％</span>
                    </td>
                    <td class="text-end">{{ progress.progress_start + (progress.progress_rate ?? 0) }}％</td>
                    <td class="text-end">{{ $helper.number(progress.phase.sales_price * progress.progress_rate / 100) }}</td>
                </tr>
            </tbody>
            <tfoot>
                <td class="p-3">合計</td>
                <td class="text-end p-3">{{ $helper.number(project_sales_price_total) }}</td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-end p-3">{{ $helper.number(monthly_sales_price_total) }}</td>
            </tfoot>
        </table>
        <div class="text-end">
            <button class="btn btn-primary" @click="save()" v-if="editable">更新</button>
        </div>
    </section>

    <section class="section" v-else-if="profit.project.project_type === ProjectType.CONTINUOUS">
        <header class="section-title">
            <h2>売上 <small>(継続)</small></h2>
        </header>
        <div class="row mb-3">
            <div class="col-4">
                <label class="form-label">標準売上</label>
                <div class="form-readonly text-end">{{ $helper.number(profit.project.monthly_sales) }} 円</div>
            </div>
            <div class="col-4">
                <label class="form-label">当月売上</label>
                <div v-if="editable" class="input-group">
                    <form-input-number v-model="profit.sales_price"></form-input-number>
                    <span class="input-group-text">円</span>
                </div>
                <div v-else class="form-readonly text-end">{{ $helper.number(profit.sales_price) }} 円</div>
            </div>
        </div>
        <div class="text-end">
            <button class="btn btn-primary" @click="save()" v-if="editable">更新</button>
        </div>
    </section>

    <section class="section" v-else-if="profit.project.project_type === ProjectType.TIME_AND_MATERIAL">
        <header class="section-title">
            <h2>売上 <small>(工数比例)</small></h2>
        </header>
        <div class="row align-items-end mb-3">
            <div class="col-4">
                <label class="form-label">請求単価</label>
                <div class="form-readonly text-end">{{ $helper.number(profit.project.labor_unit_sales) }} 円/H</div>
            </div>
            <div class="col-4"> 
                <label class="form-label">請求額</label>
                <div class="form-readonly text-end">{{ $helper.number(profit.sales_price) }} 円</div>
            </div>
            <div class="col-4">
                <label class="form-label">調整タイプ</label>
                <form-select v-model="profit.adjustment_type" :options="AdjustmentType.options()"></form-select>
            </div>
            <div class="col-4">
                <label class="form-label">調整額</label>
                <form-input-number v-if="profit.adjustment_type === 0" v-model="profit.adjustment_amount" disabled></form-input-number>
                <form-input-number v-else v-model="profit.adjustment_amount"></form-input-number>
            </div>
            <div class="col-6">
                <label class="form-label">調整備考</label>
                <form-input v-if="profit.adjustment_type === 0" v-model="profit.adjustment_note" disabled></form-input>
                <form-input v-else v-model="profit.adjustment_note"></form-input>
            </div>
            <div class="col-2">
                <button type="submit" class="btn btn-primary" @click="save()">保存</button>
            </div>
        </div>
    </section>

    <section class="section">
        <header class="section-title">
            <h2>労務費</h2>
            <router-link :to="{ name: 'WorkDetail', query: work_detail_query }" class="btn btn-outline-primary">稼働明細</router-link>
        </header>
        <table class="table">
            <thead>
                <tr>
                    <th class="col-6">スタッフ</th>
                    <th class="col-6">稼働時間</th>
                    <th class="col-6">標準単価</th>
                    <th class="col-6">小計</th>
                </tr>
            </thead>
            <template v-if="!labor_cost_loading">
                <tbody>
                    <tr v-for="labor_cost_stat of labor_cost_stats" :key="labor_cost_stat.user.user_id">
                        <td>{{ labor_cost_stat.user.user_name }}</td>
                        <td class="text-end">{{ $helper.clock(labor_cost_stat.work_time) }}</td>
                        <td class="text-end">{{ $helper.number(labor_cost_stat.unit_cost) }} 円/H</td>
                        <td class="text-end">{{ $helper.number(labor_cost_stat.labor_cost) }} 円</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td></td>
                        <td class="text-end">{{ $helper.clock(work_time_total) }}</td>
                        <td></td>
                        <td class="text-end">{{ $helper.number(labor_cost_total) }} 円</td>
                    </tr>
                </tfoot>
            </template>
            <template v-else>
                <tbody>
                    <tr v-for="i in Array(3)" :key="i">
                        <td><text-loader :width="8"></text-loader></td>
                        <td class="text-end"><text-loader :width="6"></text-loader></td>
                        <td class="text-end"><text-loader :width="6"></text-loader></td>
                        <td class="text-end"><text-loader :width="6"></text-loader></td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td></td>
                        <td class="text-end"><text-loader :width="6"></text-loader></td>
                        <td></td>
                        <td class="text-end"><text-loader :width="6"></text-loader></td>
                    </tr>
                </tfoot>
            </template>
        </table>
    </section>

    <section class="section">
        <header class="section-title">
            <h2>外注費</h2>
        </header>
        <div class="row border-bottom">
            <div class="col-8"><label class="form-label">外注先</label></div>
            <div class="col-4"><label class="form-label">金額</label></div>
            <div class="col-8"><label class="form-label">摘要</label></div>
            <div class="col-4"></div>
        </div>
        <template v-if="subcontract_cost_loading">
            <form class="row py-3 border-bottom" v-for="i in Array(2)" :key="i">
                <div class="col-8">
                    <input-loader type="select"></input-loader>
                </div>
                <div class="col-4">
                    <input-loader type="number"></input-loader>
                </div>
                <div class="col-8">
                    <input-loader></input-loader>
                </div>
                <div class="col-4">
                    <button type="button" disabled class="btn btn-primary">更新</button>
                    <button type="button" disabled class="btn btn-outline-danger ms-3">削除</button>
                </div>
            </form>
            <form class="row py-3 border-bottom">
                <div class="col-8">
                    <form-select-object-search v-model="subcontract_cost_to_add.subcontractor" :options="subcontractors" option_value="subcontractor_id" option_label="subcontractor_name" disabled></form-select-object-search>
                </div>
                <div class="col-4">
                    <form-input-number v-model="subcontract_cost_to_add.price" disabled></form-input-number>
                </div>
                <div class="col-8">
                    <form-input v-model="subcontract_cost_to_add.note" disabled></form-input>
                </div>
                <div class="col-4">
                    <button type="button" disabled class="btn btn-primary">追加</button>
                </div>
            </form>
        </template>
        <template v-else-if="editable">
            <form class="row py-3 border-bottom" v-for="cost of subcontract_costs" :key="cost.subcontract_cost_id" @submit.prevent="editSubcontractCost(cost)">
                <div class="col-8">
                    <form-select-object-search v-model="cost.subcontractor" :options="subcontractors" option_value="subcontractor_id" option_label="subcontractor_name" required :disabled="subcontractors.length === 0"></form-select-object-search>
                </div>
                <div class="col-4">
                    <form-input-number v-model="cost.price" required></form-input-number>
                </div>
                <div class="col-8">
                    <form-input v-model="cost.note"></form-input>
                </div>
                <div class="col-4">
                    <button type="submit" class="btn btn-primary">更新</button>
                    <button type="button" class="btn btn-outline-danger ms-3" @click="removeSubcontractCost(cost)">削除</button>
                </div>
            </form>
            <form class="row py-3 border-bottom" @submit.prevent="addSubcontractCost()">
                <div class="col-8">
                    <form-select-object-search v-model="subcontract_cost_to_add.subcontractor" :options="subcontractors" option_value="subcontractor_id" option_label="subcontractor_name" required :disabled="subcontractors.length === 0"></form-select-object-search>
                </div>
                <div class="col-4">
                    <form-input-number v-model="subcontract_cost_to_add.price" required></form-input-number>
                </div>
                <div class="col-8">
                    <form-input v-model="subcontract_cost_to_add.note"></form-input>
                </div>
                <div class="col-4">
                    <button type="submit" class="btn btn-primary">追加</button>
                </div>
            </form>
        </template>
        <template v-else-if="subcontract_costs.length > 0">
            <div class="row border-bottom" v-for="cost of subcontract_costs" :key="cost.subcontract_cost_id" @submit.prevent="editSubcontractCost(cost)">
                <div class="col-8 p-3">
                    {{ cost.subcontractor.subcontractor_name }}
                </div>
                <div class="col-4 p-3 text-end">
                    {{ $helper.number(cost.price) }}
                </div>
                <div class="col-12 p-3">
                    {{ cost.note }}
                </div>
            </div>
        </template>
        <template v-else>
            <div class="p-3 text-secondary">外注費はありません</div>
        </template>
    </section>

    <section class="section">
        <header class="section-title">
            <h2>諸経費</h2>
        </header>
        <div class="row border-bottom">
            <div class="col-4"><label class="form-label">金額</label></div>
            <div class="col-16"><label class="form-label">摘要</label></div>
            <div class="col-4"></div>
        </div>

        <template v-if="incidental_cost_loading">
            <form class="row py-3 border-bottom">
                <div class="col-4">
                    <input-loader type="number"></input-loader>
                </div>
                <div class="col-16">
                    <input-loader></input-loader>
                </div>
                <div class="col-4">
                    <button type="button" disabled class="btn btn-primary">更新</button>
                    <button type="button" disabled class="btn btn-outline-danger ms-3">削除</button>
                </div>
            </form>
            <form class="row py-3 border-bottom">
                <div class="col-4">
                    <form-input-number v-model="incidental_cost_to_add.price" disabled></form-input-number>
                </div>
                <div class="col-16">
                    <form-input v-model="incidental_cost_to_add.note" disabled></form-input>
                </div>
                <div class="col-4">
                    <button type="button" disabled class="btn btn-primary">追加</button>
                </div>
            </form>
        </template>
        <template v-else-if="editable">
            <form class="row py-3 border-bottom" v-for="cost of incidental_costs" :key="cost.incidental_cost_id" @submit.prevent="editIncidentalCost(cost)">
                <div class="col-4">
                    <form-input-number v-model="cost.price" required></form-input-number>
                </div>
                <div class="col-16">
                    <form-input v-model="cost.note" required></form-input>
                </div>
                <div class="col-4">
                    <button type="submit" class="btn btn-primary">更新</button>
                    <button type="button" class="btn btn-outline-danger ms-3" @click="removeIncidentalCost(cost)">削除</button>
                </div>
            </form>
            <form class="row py-3 border-bottom" @submit.prevent="addIncidentalCost()">
                <div class="col-4">
                    <form-input-number v-model="incidental_cost_to_add.price" required></form-input-number>
                </div>
                <div class="col-16">
                    <form-input v-model="incidental_cost_to_add.note" required></form-input>
                </div>
                <div class="col-4">
                    <button type="submit" class="btn btn-primary">追加</button>
                </div>
            </form>
        </template>
        <template v-else-if="incidental_costs.length > 0">
            <div class="row border-bottom" v-for="cost of incidental_costs" :key="cost.incidental_cost_id" @submit.prevent="editIncidentalCost(cost)">
                <div class="col-4 p-3 text-end">
                    {{ $helper.number(cost.price) }}
                </div>
                <div class="col-20 p-3">
                    {{ cost.note }}
                </div>
            </div>
        </template>
        <template v-else>
            <div class="p-3 text-secondary">諸経費はありません</div>
        </template>
    </section>
</template>

<script>
import Profit from "@/models/entities/profit";
import LaborCostStat from "@/models/entities/labor-cost-stat";
import Subcontractor from "@/models/entities/subcontractor";
import SubcontractCost from "@/models/entities/subcontract-cost";
import IncidentalCost from "@/models/entities/incidental-cost";
import ProjectType from "@/models/enums/project-type";
import FormInput from '@/components/forms/FormInput';
import FormInputNumber from '@/components/forms/FormInputNumber';
import FormSelectObjectSearch from '@/components/forms/FormSelectObjectSearch';
import InlineLoader from '@/components/tools/InlineLoader';
import InputLoader from '@/components/tools/InputLoader';
import TextLoader from '@/components/tools/TextLoader';
import AdjustmentType from "@/models/enums/adjustment-type";
import FormSelect from '@/components/forms/FormSelect';

export default {
    name: 'ProfitEdit',
    components: {
        FormInput,
        FormInputNumber,
        FormSelectObjectSearch,
        InlineLoader,
        InputLoader,
        TextLoader,
        FormSelect
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            profit_id: parseInt(this.$route.params.profit),
            loading: true,
            labor_cost_loading: true,
            subcontract_cost_loading: true,
            incidental_cost_loading: true,
            editable: this.$store.getters['auth/canEditProfit'](),
            profit: new Profit(),
            labor_cost_stats: [],
            subcontract_costs: [],
            subcontract_cost_to_add: new SubcontractCost(),
            incidental_costs: [],
            incidental_cost_to_add: new IncidentalCost(),
            subcontractors: [],
            ProjectType,
            AdjustmentType,
            adjustment_type: null,
            adjustment_amount: 0,
            adjustment_note: null,
        }
    },
    computed: {
        gross_profit_rate() {
            if (!this.profit || !this.profit.sales_price) {
                return "-";
            }
            return Math.floor(this.profit.gross_profit / this.profit.sales_price * 100) + "%";
        },
        project_sales_price_total() {
            if (!this.profit || this.profit.project.project_type !== ProjectType.ONE_OFF) {
                return null;
            }

            return this.profit.progresses.reduce((sum, progress) => sum + progress.phase.sales_price, 0);
        },
        monthly_sales_price_total() {
            if (!this.profit || this.profit.project.project_type !== ProjectType.ONE_OFF) {
                return null;
            }

            return this.profit.progresses.reduce((sum, progress) => sum + Math.floor(progress.phase.sales_price * progress.progress_rate * 0.01), 0);
        },
        work_time_total() {
            return this.labor_cost_stats.reduce((sum, stat) => sum + stat.work_time, 0);
        },
        labor_cost_total() {
            return this.labor_cost_stats.reduce((sum, stat) => sum + stat.labor_cost, 0);
        },
        work_detail_query() {
            return {
                month_from: this.profit?.month,
                month_to: this.profit?.month,
                project: this.profit?.project.project_id
            }
        }
    },
    mounted() {
        this.fetch();

        this.$http.get(`/profit/${this.profit_id}/labor-cost`)
        .then((response) => {
            this.labor_cost_stats = response.data.map((row) => new LaborCostStat(row));
            this.labor_cost_loading = false;
        })

        this.$http.get(`/profit/${this.profit_id}/subcontract-cost`)
        .then((response) => {
            this.subcontract_costs = response.data.map((row) => new SubcontractCost(row));
            this.subcontract_cost_loading = false;
        })

        this.$http.get(`/profit/${this.profit_id}/incidental-cost`)
        .then((response) => {
            this.incidental_costs = response.data.map((row) => new IncidentalCost(row));
            this.incidental_cost_loading = false;
        })
    },
    methods: {
        fetch() {
            this.$http.get(`/profit/${this.profit_id}`)
            .then((response) => {
                this.profit = new Profit(response.data);
                this.loading = false;
                this.fetchMaster();
            })
        },
        fetchMaster() {
            this.$http.get('/list/subcontractor', {params: {month: this.profit.month}})
            .then((response) => {
                this.subcontractors = response.data.map((row) => new Subcontractor(row));
            })
        },
        save() {
            this.startScreenLoading();

            this.$http.put(`/profit/${this.profit_id}`, this.profit)
            .then((response) => {
                this.profit = new Profit(response.data);
                this.showMessage('更新しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        fix() {
            this.startScreenLoading();

            this.$http.put(`/profit/${this.profit_id}/fix`)
            .then((response) => {
                this.profit = new Profit(response.data);
                this.showMessage('確定しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        unfix() {
            this.startScreenLoading();

            this.$http.put(`/profit/${this.profit_id}/unfix`)
            .then((response) => {
                this.profit = new Profit(response.data);
                this.showMessage('未確定に戻しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        addSubcontractCost() {
            this.startScreenLoading();

            this.$http.post(`/profit/${this.profit_id}/subcontract-cost`, this.subcontract_cost_to_add)
            .then((response) => {
                this.subcontract_costs.push(new SubcontractCost(response.data));
                this.subcontract_cost_to_add = new SubcontractCost();
                this.fetch();
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        editSubcontractCost(subcontract_cost) {
            this.startScreenLoading();

            this.$http.put(`/profit/${this.profit_id}/subcontract-cost/${subcontract_cost.subcontract_cost_id}`, subcontract_cost)
            .then(() => {
                this.fetch();
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        removeSubcontractCost(subcontract_cost) {
            this.startScreenLoading();

            this.$http.delete(`/profit/${this.profit_id}/subcontract-cost/${subcontract_cost.subcontract_cost_id}`)
            .then(() => {
                this.subcontract_costs = this.subcontract_costs.filter((row) => row.subcontract_cost_id !== subcontract_cost.subcontract_cost_id)
                this.fetch();
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        addIncidentalCost() {
            this.startScreenLoading();

            this.$http.post(`/profit/${this.profit_id}/incidental-cost`, this.incidental_cost_to_add)
            .then((response) => {
                this.incidental_costs.push(new IncidentalCost(response.data));
                this.incidental_cost_to_add = new IncidentalCost();
                this.fetch();
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        editIncidentalCost(incidental_cost) {
            this.startScreenLoading();

            this.$http.put(`/profit/${this.profit_id}/incidental-cost/${incidental_cost.incidental_cost_id}`, incidental_cost)
            .then(() => {
                this.fetch();
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        removeIncidentalCost(incidental_cost) {
            this.startScreenLoading();

            this.$http.delete(`/profit/${this.profit_id}/incidental-cost/${incidental_cost.incidental_cost_id}`)
            .then(() => {
                this.incidental_costs = this.incidental_costs.filter((row) => row.incidental_cost_id !== incidental_cost.incidental_cost_id)
                this.fetch();
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
    },
}
</script>

<style scoped>
</style>
